.work-page{

    .container-filter-tools{
        display: flex;
        position: relative;
        width: 100%;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 1em;

        .filter-outil{
            opacity: 0.3;
            width: 50px;
            aspect-ratio: 1;
            cursor: pointer;

            img{
                width: 100%;
                aspect-ratio: 1;
                object-fit: contain;
            }
        }

        .filter-outil.select{
            opacity: 1;
        }
    }


    .contain-list-work{
        display: grid;
        gap: 1em;
        grid-template-columns: 50% 50%;

    }

    .work-elem{
        border-radius: 15px;
        background-color: $color_background_1;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        min-height: 200px;
        cursor: pointer;

        .bg{
            position: absolute;
            right: -20%;
            top: -10px;
            height: 110%;
            aspect-ratio: 1;
            z-index: 1;
            overflow: hidden;
            border-radius: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.5;
            }
        }

        .texts{
            position: relative;
            z-index: 2;
            padding: 1em 2em;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title{
                text-transform: uppercase;
                font-size: 1.1em;
                font-weight: bolder;
                color: white;
            }

            .date{
                font-size: 0.8em;
            }

            .desc{
                font-size: 0.8em;
                opacity: 0.8;
                margin-top: 1em;
                max-width: 50%;
            }
        }
    }
}



@media screen and (max-width:560px) {
    .work-page .contain-list-work{
        grid-template-columns: 1fr;
    }
}