
.loader-login{

    .lds-ripple {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 18px;
        aspect-ratio: 1;
      }
      .lds-ripple div {
        position: absolute;
        border: 2px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        box-sizing: border-box;
        aspect-ratio: 1;
      }
      .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
      }

      @keyframes lds-ripple {
        0% {
          width: 0;
          opacity: 0;
        }
        4.9% {
          width: 0;
          opacity: 0;
        }
        5% {
          width: 0;
          opacity: 1;
        }
        100% {
          width: 30px;
          opacity: 0;
        }
      }
      
}