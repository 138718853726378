.contactpage{

    li{
        margin: 1em 0;

        svg{
            color: $color_strong2;
        }

        span{
            margin-left: 1em;
        }

        img{
            width: 18px;
            aspect-ratio: 1;
            vertical-align: baseline;
        }
    }
}