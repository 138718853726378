.container-login{
    display: flex;
    flex-direction: column;
    padding: 1em;
    background-color:$color_background_1;
    border-radius: 8px;
    max-width: 350px;

    .container-input{
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        margin: 0.5em 0;

        input{
            width: 100%;
            padding: 13px;
            padding-top: 25px;
            background-color: transparent;
            color: $color_font;
            border: none;
            outline: none;
            border-radius: 5px;
            border:1px solid $color_font;
            font-size: 1em;
            font-family: $font_family;
        }

        label{
            position: absolute;
            top: 10px;
            left: 15px;
            color:$color_font;
            font-size: 0.8em;
        }

        .ico{
            position: absolute;
            right: 17px;
            font-size: 1.3em;
        }
    }

    #submitLogin{
        padding: 1em;
        background:$color_strong;
        font-family: $font-family;
        font-weight: bold;
        border-radius: 5px;
        color: $color_font;
        cursor: pointer;
        margin: 0.5em 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.backLoginForm{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}