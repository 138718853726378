.pageFormAdd{

    form{
        display: flex;
        flex-direction: column;

        label{
            margin: 5px 0;
            display: flex;
            flex-direction: column;
        }

        input,
        textarea{
            padding: 5px;
            background-color: $color_background_1;
            color: white;
            border: none;
            outline: none;
        }

        .checbox-label{
            flex-direction: row;

            input{
                margin-left: 1em;
            }
        }

        input[type='submit']{
            background-color: $color_strong2;
            color: white;
            padding: 1em;
            border: none;
            outline: none;
            border-radius: 5px;
            font-weight: bolder;
            margin-top: 2em;
            cursor: pointer;
        }

        .container-select-tool{
            width: 100%;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;

            .contain-tool{
                opacity: 0.3;
                filter: grayscale(1);
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                aspect-ratio: 1;

                img{
                    width: 100%;
                    aspect-ratio: 1;
                    object-fit: contain;
                }
            }

            .contain-tool.select{
                opacity: 1;
                filter: grayscale(0);
            }
        }


        .container-image,
        .add-img,
        .containermainimage{
            max-width: 100%;
            max-height: 400px;
            aspect-ratio: 1;
            border-radius: 15px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            .loader{
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: grey;
                z-index: 2;
                opacity: 0.7;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .deleteItem{
                position: absolute;
                top: -20px;
                right: -20px;
                z-index: 3;
                padding: 1em;
                font-size: 3em;
                background-color: rgba(4, 217, 196, 0.7);
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                aspect-ratio: 1;
                overflow: hidden;
            }
        }

        .container-images{
            margin-top: 1em;
            display: grid;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            position: relative;
            width: 100%;
            gap: 10px;


            .add-img{
                border: 1px solid $color_strong2;
                cursor: pointer;
                color: $color_strong2;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3em;
            }

        }

        

        .error{

            input,
            textarea{
                background-color: rgb(63, 11, 11);
            }

            input[type='checkbox']{
                outline: 2px solid rgb(63, 11, 11);
            }
            
        }
    }
}



@media screen and (max-width:560px) {
    .pageFormAdd form .container-images{
        justify-content: space-between;
    }

    .pageFormAdd form .container-images{
        grid-template-columns: 1fr 1fr;
    }

    .pageFormAdd form .container-images .containermainimage{
        width: 100%;
        margin: 5px;
    }
}